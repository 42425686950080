import React from "react"
import { graphql, Link } from "gatsby"

import theme from "../themes/theme"

import { PageLayout } from "../components/PageLayout"

import {
  // HeroGrid,
  // HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroExtra,
  // HeroButton,
} from "../components/HeroSection"

import { StyledIcon } from "../components/StyledIcon"

import {
  SocialFacebook,
  SocialLinkedIn,
  SocialInstagram,
  SocialTwitter,
} from "../components/SocialIcons"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
} from "../components/SectionBlocks"

import SEO from "../components/seo"

const AssessmentBooked = ({ data, location }) => {
  return (
    <PageLayout>
      <SEO
        title="Assessment Booked"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Coaching professionals to look great, get stronger & play harder"
        url={location.origin + location.pathname}
      />
      <RowSection
        rowMargin="0%"
        backgroundUrl={data?.heroImage?.childImageSharp?.fluid}
        bgX="60%"
        bgY="0%"
        style={{ flexGrow: "1" }}
        // overlay="rgba(255, 255, 255, 0.8)"
        // overlay="linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 10%)"
        // opacity="0.9">
      >
        <TwoColumnSection
          columnLeftWidth="50%"
          columnRightWidth="50%"
          sideMargin="5%"
          columnLeftTop="true">
          <TwoColumnSectionLeft>
            <div
              style={{
                borderRadius: "12px",
                height: "100%",
                marginTop: "5%",
                marginBottom: "5%",
                backgroundColor: theme.colors.primaryBlueNormal,
                marginLeft: "5%",
                marginRight: "5%",
                alignItems: "center",
                textAlign: "center",
              }}>
              <HeroHeadline>
                <h1
                  style={{
                    color: theme.colors.white,
                    textAlign: "center",
                    padding: "10px",
                    lineHeight: theme.spacings.large,
                  }}>
                  Free Assessment Booked
                </h1>
              </HeroHeadline>
              <HeroSubHead>
                <p
                  style={{
                    color: theme.colors.light1,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}>
                  Congratulations! Your Free Assessment is confirmed.
                </p>
                <p
                  style={{
                    color: theme.colors.light1,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}>
                  We have just sent you a confirmation email with your booking details. At the
                  appointment's date and time, please be near your phone.
                </p>
                <p
                  style={{
                    color: theme.colors.light1,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}>
                  We understand schedules change, so if you need to move your Free Assessment with
                  one of our coaches, please let us know.
                </p>
                <p
                  style={{
                    color: theme.colors.light1,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}>
                  You can do this by simply clicking on the{" "}
                  <Link to="/free-assessment">Free Assessment</Link> link again and entering in the
                  same email.
                </p>
                <p
                  style={{
                    color: theme.colors.light1,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}>
                  We look forward to helping you achieve your health goals!
                </p>
              </HeroSubHead>
            </div>
          </TwoColumnSectionLeft>
          <TwoColumnSectionRight alignItems="center" justifyContent="center">
            <HeroHeadline>
              <h1
                style={{
                  color: theme.colors.white,
                  textAlign: "center",
                }}>
                While You're Waiting...
              </h1>
            </HeroHeadline>
            <HeroSubHead>
              <p style={{ color: theme.colors.light1, textAlign: "center" }}>
                Come and join our community and get to know us better.
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <StyledIcon padding="3px" size="50px">
                  <SocialLinkedIn paddingLeft="10px" />
                  <SocialFacebook paddingLeft="10px" />
                  <SocialInstagram paddingLeft="10px" />
                  <SocialTwitter paddingLeft="10px" />
                </StyledIcon>
              </div>
            </HeroSubHead>
          </TwoColumnSectionRight>
        </TwoColumnSection>
      </RowSection>
    </PageLayout>
  )
}

{
  /* <div
                className="js_kt_asset_embed js_kartra_trackable_object"
                class=".js_kt_asset_embed js_kartra_trackable_object"
                data-kt-type="calendar"
                data-kt-embed="inline"
                data-kt-value="jYxLSpeuGEXc"
                data-kt-owner="Brl61K8g"
                data-kt-accent="#39b9dd">
                this is the calendar
              </div>
              <script
                type="text/javascript"
                src="https://app.kartra.com/js/build/front/embed/calendar.js"></script> */
}

export default AssessmentBooked

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-kettlebell-row-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    socialImage: file(absolutePath: { regex: "/LiftologyProject-Banner-Squat-1200x628.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
